.card_v {
  position: relative;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  margin: 10px;
  transition: 0.25s ease-in;
}

.card_v .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
}

.card_v .content {
  position: absolute;
  bottom: 10px;
  left: 25px;
}

.card_v .content h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #f2f2f2;
  margin-bottom: 10px;
  margin-top: 0;
}

.card_v .content h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #f2f2f2;
  margin-bottom: 10px;
  margin-top: 0;
}

.card_v .fav {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.card_v .fav i {
  color: white;
}

.card_v:hover {
  transform: scale(1.05);
}

.card_v:hover {
  background-position: top;
}
