.backdrop{
  min-height: 35vh;
  background-size: cover;
  background-position: center center;
}

.backdrop-inner{
  background-color: rgba(0, 0, 0, 0.8);
  background-size: cover;
  min-height: 35vh;
  width: 100%;
  position: relative;
  left: 0px;
}