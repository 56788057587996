.spinner-container{
  position: absolute;
  width: 100%;
  top: 10%;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.spinner{
  padding: 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}