@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap');


* {
    font-family: 'Work Sans', sans-serif;
}

* {
    scrollbar-width: none;
    scrollbar-color: lightgray white;
}

* ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
}

* ::-webkit-scrollbar-track {
    background: whitesmoke;
}

* ::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 20px;
    border: 1px solid darkgray;
}

* ::-webkit-scrollbar-thumb:hover {
    background: lightgrey;
}

ion-content {
    --overflow: overlay;
}

.header-logo{
    height: 45px;
    width: 45px;
}

.home-card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
}

.skills-image-container{
    margin-top: 20px;
}

.skills-image{
    max-height: 250px;
}

section h2 {
    font-weight: bolder;
}

.un-capitalize{
    text-transform: none;
}
