

ion-toolbar {
  --padding-start: var(--content-padding) !important;
  --padding-end: var(--content-padding) !important;
  --ion-padding: 0;
  ion-button.logo-btn {
    margin: 0;
    --padding-start: 0;
  }
  ion-button.menu-icon-btn {
    --padding-end: 0;
    margin: 0;
    ion-icon {
      font-size: 2.5rem;
    }
  }
}

.menu-btns {
  display: none;
  &__active {
    position: relative;
  }
  &__active::after {
    position: absolute;
    content: '';
    bottom: -12px;
    width: 100%;
    height: 5px;
    left: 0;
    opacity: 1;
    background: var(--ion-color-danger);
  }
}

@media screen and (min-width: 576px) {
  .menu-btns {
    display: unset;
  }
  ion-button.menu-icon-btn {
    display: none;
  }
}
